import * as CryptoJS from "crypto-js";

const decryptAES = (encryptedBase64, keyBase64, iv) => {
  const key2 = CryptoJS.enc.Base64.parse(keyBase64);
  const ivHex = CryptoJS.enc.Hex.parse(
    Array.from(iv)
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("")
  );

  const encryptedData = CryptoJS.enc.Base64.parse(encryptedBase64);

  const decrypted = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, key2, {
    iv: ivHex,
  });

  return decrypted.toString(CryptoJS.enc.Latin1);
};

export const prepareToDecrypt = (data) => {
  const encryptedMessageBytes = CryptoJS.enc.Base64.parse(data);

  const encryptedText = new Uint8Array(encryptedMessageBytes.sigBytes);

  for (let i = 0; i < encryptedMessageBytes.sigBytes; i++) {
    encryptedText[i] =
      (encryptedMessageBytes.words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
  }

  const keyLength = encryptedText[0];
  const vectorLength = encryptedText[1];

  const key = encryptedText.slice(2, 2 + keyLength);

  const vector = encryptedText.slice(
    encryptedText.length - vectorLength,
    encryptedText.length
  );
  const message = encryptedText.slice(
    2 + keyLength,
    encryptedText.length - vectorLength
  );

  const base64Key = btoa(String.fromCharCode.apply(null, key));
  const base64Message = btoa(String.fromCharCode.apply(null, message));

  return decryptAES(base64Message, base64Key, vector);
};
